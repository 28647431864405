<template>
  <div class="information">
    <h2>个人资料</h2>
    <div class="space">
      <div class="item-container">
        <div class="item1 d-f">
          <p>头像</p>
          <div class="avatar">
            <img src="@/assets/image/user_avatar_big.png" alt="" v-if="avatar === null" />
            <img :src="avatar" alt="" v-else />
            <a-upload
              name="file"
              :showUploadList="false"
              :customRequest="customRequest"
              :headers="headers"
              :before-upload="beforeUpload"
              accept=".jpg, .png, .jpeg"
            >
              <div class="repair-avatar">
                <img
                  src="@/assets/image/user_information_repair.png"
                  class="repair-img"
                />
                <h6>修改头像</h6>
              </div>
            </a-upload>
          </div>
        </div>
        <a-form-model
          class="form"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          hideRequiredMark
        >
          <a-form-model-item prop="username" label="用户名">
            <a-input v-model="form.username" />
          </a-form-model-item>
          <a-tooltip placement="right">
            <template slot="title">
              <span>修改用户名需要重新登录哦~</span>
            </template>
            <a-icon type="question-circle" class="question-circle" />
          </a-tooltip>
          <a-form-model-item prop="name" label="姓名">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="onSubmit"> 保存信息 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUserInfo,
  getStsInfo,
  updateAvatar,
  getAvatarUrl,
  updateInfo,
} from "@/axios/acl.js";
import Client from "@/utils/oss.js";
export default {
  data() {
    return {
      id: "",
      avatar: "",
      form: {
        username: "",
        name: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[A-Za-z][0-9A-Za-z]{2,12}$/;
              if (!reg.test(value)) {
                callbackFn("用户名只能包括数字(不以数字开头)和字母且在3~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            whitespace: true,
            trigger: "blur",
          },
        ],
      },
      headers: {
        authorization: "authorization-text",
        token: this.$ls.get("token"),
      },
      dataObj: null,
    };
  },
  methods: {
    //上传文件之前的操作
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("您只能上传图片格式");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("文件不能大于5M");
      }
      return isLt5M && isJpgOrPng;
    },
    //上传图片到oss
    async customRequest(action) {
      let res0 = await getStsInfo("web/oss/getStsInfo/");
      if (res0.success) {
        const {
          region,
          Bucket,
          AccessKeyId,
          AccessKeySecret,
          SecurityToken,
        } = res0.data.data;
        this.dataObj = {
          region: region,
          bucket: Bucket,
          accessKeyId: AccessKeyId,
          accessKeySecret: AccessKeySecret,
          stsToken: SecurityToken,
        };
      }
      try {
        const client = Client(this.dataObj),
          file = action.file;
        //修改命名
        var fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const random_name = "avatar/" + this.id + "_" + "avatar." + fileExtension;
        let result = await client.put(random_name, file);
        // console.log(result);
        if (result.res.statusCode === 200) {
          updateAvatar("acl/user/userUpdateAvatar/", {
            avatar: random_name,
            id: this.id,
          }).then((res) => {
            //更新成功
            if (res.success) {
              //获取头像
              getAvatarUrl("web/oss/getAvatarUrl/", { avatar: random_name }).then(
                (res) => {
                  // console.log(res);
                  if (res.success) {
                    this.$message.success("上传成功");
                    this.getUserInfo();
                  }
                }
              );
            } else {
              this.$message.error(res.message);
            }
          });
        }
      } catch (error) {
        this.$message.error("上传失败");
      }
    },
    //提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm({
            title: "修改用户名需要重新登录哦~",
            onOk: () => {
              let userInfo = this.$ls.get("userInfo");
              let params = {};
              params.id = this.id;
              params.name = this.form.name;
              params.username = this.form.username;
              params.phone = userInfo.phone;
              params.schoolNum = userInfo.schoolNum;
              if (params.username != userInfo.username) {
                updateInfo("acl/user/userUpdateInfo/", params).then((res) => {
                  if (res.success) {
                    this.$message.success("修改成功,请重新登录");
                    this.$ls.set("userInfo", null);
                    this.$ls.set("token", null);
                    window.open("https://cloud.jsxniu.com/login", "_self");
                  } else {
                    this.$message.error(res.message);
                  }
                });
              } else {
                updateInfo("acl/user/userUpdateInfo/", params).then((res) => {
                  if (res.success) {
                    this.$message.success("修改成功");
                    this.getUserInfo();
                  } else {
                    this.$message.error(res.message);
                  }
                });
              }
            },
          });
        }
      });
    },
    //获取用户信息
    getUserInfo() {
      getUserInfo("acl/index/info").then((res) => {
        if (res.success) {
          this.$ls.set("userInfo", res.data, 60 * 60 * 1000 * 24);
          this.form.username = this.$ls.get("userInfo").username;
          this.form.name = this.$ls.get("userInfo").name;
          this.avatar = this.$ls.get("userInfo").avatar;
          this.id = this.$ls.get("userInfo").id;
          this.$emit("changeUserInfo", this.$ls.get("userInfo"));
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  created() {
    this.form.username = this.$ls.get("userInfo").username;
    this.form.name = this.$ls.get("userInfo").name;
    this.avatar = this.$ls.get("userInfo").avatar;
    this.id = this.$ls.get("userInfo").id;
  },
};
</script>

<style scoped lang="less">
.information {
  width: 936px;
  height: 710px;
  background: #fff;
  padding-left: 36px;
  padding-top: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: #333333;
  }
  .space {
    margin-top: 48px;
    .item-container {
      width: 350px;
      height: 400px;
      .item1 {
        width: 100%;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 0;
          margin-left: 13px;
        }
        .avatar {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          margin-left: 24px;
          cursor: pointer;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          .repair-avatar {
            display: none;
            .repair-img {
              position: absolute;
              top: 0;
              left: 0;
              width: 120px;
              height: 120px;
            }
            h6 {
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              position: absolute;
              bottom: 5px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        .avatar:hover .repair-avatar {
          display: block;
        }
      }
      .item2 {
        width: 100%;
        margin-top: 28px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 0;
          margin-left: 13px;
        }
        .userid {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          margin-left: 10px;
        }
      }
      .form {
        margin-top: 24px;
        position: relative;
        ::v-deep .ant-form-item-label {
          label {
            color: #999999;
          }
        }
        .question-circle {
          position: absolute;
          top: 14px;
          left: 370px;
        }
      }
    }
  }
}
</style>
